<template>
    <div class="overview__sticky-header">
        <div ref="breadcrumbs" class="overview__sticky-header-breadcrumbs" />

        <button class="button button--small button--grouped button--light"
                data-testid="scroll-to-top"
                @click.prevent="scrollToTop()"
        >
            <span class="button__icon-group">
                <frm-icon class="button__icon" name="caret-up" />
                <span>
                    {{ $t('overview.actions.scroll-to-top') }}
                </span>
            </span>
        </button>
    </div>
</template>

<script>

export default {
  mounted() {
    const existingBreadcrumbs = document.querySelector('.breadcrumbs');

    if (!existingBreadcrumbs) {
      return;
    }

    const breadcrumbs = document.querySelector('.breadcrumbs').cloneNode(true);
    breadcrumbs.classList.add('breadcrumbs--smaller');

    this.$refs.breadcrumbs.appendChild(breadcrumbs);
  },

  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },

};
</script>
